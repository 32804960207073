<template>
  <div class="app flex-row align-items-center">
    <div class="container" v-if="tagId && tag">
      <h2>{{ tag.name }}</h2>
      <div v-if="tagId">
        <tree-view
          v-if="tag.allowTree"
          :id="'tag-item'"
          :path="'tag-item'"
          :label-field-formatter="labelFieldFormatter"
          :extraParams="extraParams"
          :actions="actions"
          :add-route="'AddTagItem'"
          :add-route-params="addLinkParams"
          :update-route="'UpdateTagItem'"
          :add-text="$t('addElement')"
        />
        <grid-view-table
          v-else
          :id="'tag-item-' + tagId"
          :columns="columns"
          :extended-filter-schema="extendedFilterSchema"
          :path="'tag-item'"
          :extraParams="extraParams"
          :add-route="'AddTagItem'"
          :add-route-params="addLinkParams"
          :update-route="'UpdateTagItem'"
          :add-text="$t('addElement')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import TreeView from '../../components/TreeView';
  import GridViewTable from '../../components/GridViewTable';
  import {columns} from './grid-views/tags-items';
  import {actions} from './tree-actions/tags-items';
  import {APIService} from "../../services/api";
  import { schema as extendedFilterSchema } from './filter-forms-schemas/tagItem';

  export default {
    name: 'TagItemList',
    components: {
      GridViewTable,
      TreeView,
    },
    props: ['tagId'],
    data: () => {
      return {
        tag: null,
        columns,
        actions,
        extendedFilterSchema,
      }
    },
    methods: {
      async loadTag (tagId) {
        this.tag = await APIService.get(`tag/${tagId}`)
      },
      labelFieldFormatter (item) {
        if (item.productCounts === 0) {
          return item.value;
        }

        return `${item.value}  (${item.productCounts})`;
      }
    },
    computed: {
      addLinkParams: function () {
        return {tagId: this.tagId}
      },
      extraParams: function () {
        return '&join=tag&filter=tag.id||eq||' + this.tagId
      }
    },
    watch: {
      tagId: {
        immediate: true,
        deep: true,
        handler(newValue) {
          this.loadTag(newValue);
        }
      }
    },
    beforeMount() {
      this.loadTag(this.tagId);
    },
  }
</script>
