import { actions as baseActions } from "../../../components/tree-view-actions/standart-actions";
import { statusActions } from "../../../components/tree-view-actions/status-actions";

export const actions = [
  {
    label: '',
    class: 'fa fa-sort',
    action: {
      type: 'route',
      name: 'TagItemSort',
      paramsResolver: (data) => {
        return {id: data.id}
      },
    },
  },
  {
    label: '',
    class: 'fa fa-sliders',
    action: {
      type: 'route',
      name: 'TagItemsFiltersList',
      paramsResolver: (data) => {
        return {tagItemId: data.id}
      },
    },
  },
  ...statusActions,
  ...baseActions,
]
